import moment from "moment";
import { useState } from "react";
import Flatpickr from "react-flatpickr";

interface DatepickerProps {
  defaultDate?: any;
  minDate?: string;
  handleChangeDate: Function;
  disabled?: boolean;
}

const Datepicker: React.FC<DatepickerProps> = ({
  defaultDate,
  minDate,
  handleChangeDate,
  disabled = false,
}) => {
  const today = moment().format("YYYY-MM-DD");
  const [value, setValue] = useState(defaultDate || today);

  return (
    <Flatpickr
      className="rank_datepicker"
      value={value}
      options={{
        dateFormat: "Y-m-d",
        defaultDate: [today],
        minDate: minDate || moment().startOf("year").format("YYYY-MM-DD"),
        maxDate: today,
        allowInput: true,
      }}
      disabled={disabled}
      onChange={([newValue]: any) => {
        setValue(moment(newValue).format("YYYY-MM-DD"));
        handleChangeDate(newValue);
      }}
    />
  );
};

export default Datepicker;
