import { Auth } from "aws-amplify";

export const getToken = async () => {
  try {
    const token = await Auth.currentAuthenticatedUser();
    // console.log(token)
    if (token) {
      return token.signInUserSession.idToken.jwtToken;
    }
  } catch (error) {
    return console.log(error);
  }
};

export const monthToType = (month) => {
  const dict = { 3: "1", 6: "2", 12: "3" };
  return dict?.[month] || "1";
};
