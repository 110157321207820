import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../Components/Hooks/UserHooks";

const AuthProtected = (props) => {
    const { userProfile, loading } = useAuth();
    if (loading) return null;

    return userProfile ?
        <>{props.children}</> :
        <Redirect pathname='/login' state={{ from:  props.location }} />
}

const AccessRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (<> <Component {...props} /> </>);
            }}
        />
    );
};
  
export { AuthProtected, AccessRoute };