import {
  CHANGE_LOCATION,
  FETCH_STATE_REQUEST,
  FETCH_STATE_SUCCESS,
  FETCH_STATE_FAILURE,
  SET_LOCATION,
} from "./actionType";

const INIT_STATE = {
  currentLocation: null,
  stateList: [],
  isLoading: false,
  error: null,
};

const Location = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCATION:
      return { ...state, currentLocation: action.payload };

    case SET_LOCATION:
      return { ...state, currentLocation: action.payload };

    case FETCH_STATE_REQUEST:
      return { ...state, isLoading: true, error: null };

    case FETCH_STATE_SUCCESS:
      return { ...state, isLoading: false, stateList: action.payload };

    case FETCH_STATE_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

export default Location;
