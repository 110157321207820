import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { changeLocation, changeUtility } from "../../store/actions";
import { selectLocation, selectStateList } from "../../store/location/selector";
import { useHistory } from "react-router-dom";

const LocationDropdown = () => {
  const dispatch = useDispatch();
  const currentLocation = useSelector(selectLocation);
  const stateList = useSelector(selectStateList);
  const history = useHistory();

  const [isLocationDropdown, setIsLocationDropdown] = useState(false);
  const toggleLocationDropdown = () => {
    setIsLocationDropdown(!isLocationDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isLocationDropdown}
        toggle={toggleLocationDropdown}
        className="header-item"
      >
        <DropdownToggle tag="button" type="button" className="btn dropdown-btn">
          <span className="d-flex align-items-center">
            <i className="ri-map-pin-5-line" />
            <span className="text-start ms-1">
              {currentLocation?.state_name}
            </span>
            <i className="ri-arrow-down-s-line ms-1" />
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {stateList.map((item) => (
            <DropdownItem
              key={item.state_id}
              onClick={() => {
                if (currentLocation?.state_id === item.state_id) return;
                if (
                  (currentLocation?.state_id === "TX" &&
                    item.state_id !== "TX") ||
                  (currentLocation?.state_id !== "TX" && item.state_id === "TX")
                ) {
                  history.push("/");
                }
                dispatch(changeUtility(null));
                dispatch(changeLocation(item));
                window.localStorage.setItem("location", JSON.stringify(item));
              }}
              className="d-flex align-items-center justify-content-between"
            >
              <span>{item.state_name}</span>
              {item.state_id === currentLocation?.state_id && (
                <i className="ri-check-line me-1" />
              )}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LocationDropdown;
