import { CHANGE_GET_ACCESS_MODAL_DISPLAY } from "./actionType";

const INIT_STATE = {
  display: false,
};

const Modals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_GET_ACCESS_MODAL_DISPLAY:
      return { ...state, display: action.payload };

    default:
      return state;
  }
};

export default Modals;
