module.exports = {
    google: {
        API_KEY: "",
        CLIENT_ID: "",
        SECRET: "",
    },
    facebook: {
        APP_ID: "",
    },
    api: {
        API_URL: "https://7vu0h6rh6d.execute-api.us-east-2.amazonaws.com/v1/",
    }
}