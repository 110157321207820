import { createContext } from "react";
import { Helmet } from "react-helmet";
import MyPlanRank from "../Components/MyPlanRank";
import MyPlanList from "../Components/MyPlanList";
import { MySummary } from "../Components/MySummary";
import { useSelector } from "react-redux";
import USN_MyPlansList from "../Components/USN_MyPlansList";
import USN_MyPlanRank from "../Components/USN_MyPlanRank";

export const EflDoc = createContext<any>({});

export const LoginMyPlan = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const isTX = currentLocation?.state_id === "TX";

  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex</title>
      </Helmet>
      <MySummary />
      {isTX ? <TxComps /> : <UsnComps />}
    </div>
  );
};

const TxComps = () => {
  return (
    <>
      <MyPlanRank />
      <MyPlanList />
    </>
  );
};

const UsnComps = () => {
  return (
    <>
      <USN_MyPlanRank />
      <USN_MyPlansList />
    </>
  );
};
