import { api } from "../config";
import { getToken } from "./common";

export const fetchUSNPlanList = async (
  state_id,
  created_ts,
  utility_id,
  service_type
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("created_ts", created_ts);
  formdata.append("service_type", service_type || "1");
  if (utility_id) {
    formdata.append("utility_id", utility_id);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/MyPlan/my_plans`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNPlanRank = async (
  state_id,
  utility_id,
  created_ts,
  service_type
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("utility_id", utility_id);
  formdata.append("created_ts", created_ts);
  formdata.append("service_type", service_type || "1");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/MyPlan/my_plan_rank`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNPlanDetail = async (plan_id, pd_id, date) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("plan_id", plan_id);
  formdata.append("pd_id", pd_id);
  formdata.append("date", date);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/MyPlan/my_plan_detail`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const exportUSNPlanList = async (
  state_id,
  created_ts,
  utility_id,
  service_type
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("created_ts", created_ts);
  formdata.append("service_type", service_type || "1");
  if (utility_id) {
    formdata.append("utility_id", utility_id);
  }
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
    // responseType: 'blob',
  };
  const url = `${api.API_URL}USN/MyPlan/export_my_plans`;
  const response = await fetch(url, requestOptions);
  return response;
};

export const fetchUSNPlanRankDetail = async (
  state_id,
  date,
  utility_id,
  term,
  service_type
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("date", date);
  formdata.append("utility_id", utility_id);
  formdata.append("term", term);
  formdata.append("service_type", service_type || "1");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/MyPlan/my_plan_rank_detail`;
  const response = await fetch(url, requestOptions);
  return response.json();
};
