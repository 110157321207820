import { call, put, takeLatest, all } from "redux-saga/effects";
import { FETCH_STATE_REQUEST, CHANGE_LOCATION } from "./actionType";
import { fetchStateSuccess, fetchStateFailure, setLocation } from "./action";
import { fetchAllStateList, fetchStateList } from "../../api/usnMarket";
import { StateList } from "../../Components/Common/StateList";
import { Auth } from "aws-amplify";

function* fetchStateSaga() {
  try {
    const user = yield Auth.currentUserInfo();

    let res = null;

    if (user) {
      res = yield call(fetchStateList);
    } else {
      res = yield call(fetchAllStateList);
    }

    let finalStatelist = [
      {
        state_id: "TX",
        state_name: "Texas",
      },
    ];

    const electricityList = res?.response?.electricity || [];

    if (electricityList.length > 0) {
      const filteredState = StateList.filter((state) =>
        electricityList.includes(state.state_id)
      );
      finalStatelist = [
        {
          state_id: "TX",
          state_name: "Texas",
        },
        ...filteredState,
      ];
    }
    yield put(fetchStateSuccess(finalStatelist));

    const location = yield call(() => window.localStorage.getItem("location"));
    let defaultLocation = finalStatelist?.[0] || null;

    if (location) {
      const locationData = JSON.parse(location);
      const findState = finalStatelist.find(
        (state) => state.state_id === locationData?.state_id
      );
      if (findState) {
        defaultLocation = findState;
      }
    }

    yield put(setLocation(defaultLocation));
  } catch (error) {
    console.log(error);
    yield put(fetchStateFailure(error.message));
  }
}

function* changeLocationSaga(action) {
  const newLocation = action.payload;
  yield put(setLocation(newLocation));
  yield call(() => {
    window.localStorage.setItem("location", JSON.stringify(newLocation));
  });
}

function* watchFetchState() {
  yield takeLatest(FETCH_STATE_REQUEST, fetchStateSaga);
}

function* watchChangeLocation() {
  yield takeLatest(CHANGE_LOCATION, changeLocationSaga);
}

export default function* locationSaga() {
  yield all([watchFetchState(), watchChangeLocation()]);
}
