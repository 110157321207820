import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const Logout = () => {
    const history = useHistory();

    const logout = async () => {
        try {
            await Auth.signOut();
            history.push('/');
            
        } catch (error) {
            console.error(error);
        }
    }

    logout();

    return null;
}

export default Logout;