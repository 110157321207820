
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar, Stack } from '@mui/material';
import { successSnackbar } from '../store/actions';


export const SuccessSnackbar = () => {
    const dispatch = useDispatch()

    const { openSnackbar } = useSelector((state: any) => ({
        openSnackbar: state.Layout.openSnackbar
    }))

    const handleClose = () => {
        dispatch(successSnackbar(false))
    }




    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Modified successfully, please log in again!
                </Alert>
            </Snackbar>
        </Stack>
    )
}