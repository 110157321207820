import React, { useEffect, useState } from 'react'
import Loader from './Common/Loader'
import { fetchInfo, saveInfo } from '../api/market'
import { useSelector, useDispatch } from 'react-redux';
import { changeProfileModalDisplay } from "../store/actions";
import { Button, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
// import { errornotify } from './Message';

export const EmailModal = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [planPicker, setPlanPicker] = useState(false)
    const [planReport, setPlanReport] = useState(false)
    const [isSaveing, setIsSaveing] = useState(false)
    const [failEmail, setFailEmail] = useState(false)
    const [textValue, setTextValue] = useState('')
    const [info, setInfo] = useState({ email: '', plan_picker: 0, plan_report: 0 })
    const { profileModalDisplay } = useSelector((state: any) => ({
        profileModalDisplay: state.Layout.profileModalDisplay
    }));

    useEffect(() => {
        if(profileModalDisplay) getMyInfo();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileModalDisplay])

    const handleClose = () => {
        // getMyInfo();
        dispatch(changeProfileModalDisplay(false));
    }

    const changePlanPicker = (value: any) => {
        setPlanPicker(value)
        value
            ? setInfo({
                ...info,
                plan_picker: 1,
            })
            : setInfo({
                ...info,
                plan_picker: 0,
            })
    }
    const changePlanReport = (value: any) => {
        setPlanReport(value)
        value
            ? setInfo({
                ...info,
                plan_report: 1,
            })
            : setInfo({
                ...info,
                plan_report: 0,
            })
            
    }

    const getMyInfo = async () => {
        setIsLoading(true)
        const res = await fetchInfo()
        
        if (res.status === 1) {
                setInfo({
                    email: res.response.email,
                    plan_report: +res.response.plan_report,
                    plan_picker: +res.response.plan_picker
                })
                setTextValue(res.response.email)
                changePlanPicker(+res.response.plan_picker ? true : false)
                changePlanReport(+res.response.plan_report ? true : false)

                return setIsLoading(false)
            
        } else {
            // errornotify(res.message||'Error')
            return setIsLoading(false)
        }
    }

    const handleSave = async (e: any) => {
        setIsSaveing(true)
        const email = info.email?info.email:textValue
        
        if (!email) {
            setIsSaveing(false)
            return setFailEmail(true)
        }
        let reg: any = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (!reg.test(email)) {
            setIsSaveing(false)
            return setFailEmail(true)
        }
        
        const res = await saveInfo({
            ...info,
            email
        })
        if (res.success_msg) {
            handleClose()
            setFailEmail(false)
            setIsSaveing(false)
            return
        } else {
            setIsSaveing(false)
            setFailEmail(false)
            return
        }
    }
    const inputEmail = (value: any) => {
        setInfo({
            ...info,
            email: value,
        })
    }

    return (
        <Modal
            isOpen={profileModalDisplay}
            toggle={handleClose}
            centered
        >
            <ModalHeader toggle={handleClose}>
                Email Report
            </ModalHeader>
            {!isLoading ? (
                <ModalBody>
                    <div>
                        <div className="mb-4">
                            <Label className="fs-20"></Label>
                            <p><i>Send my plan report to this email, at 09:07 am (CST)</i></p>
                            <div className="form-icon">
                                <Input type="email" width={'50%'} className="form-control form-control-icon" id="iconInput" onChange={(e: any) => inputEmail(e.target.value)} defaultValue={textValue} placeholder="example@gmail.com"/>
                                <i className="ri-mail-unread-line"></i>
                            </div>
                            {
                                failEmail &&
                                <div className="fs-14 text-danger mt-2">
                                    Please input current email
                                </div>
                            }
                        </div>
                        <div className='form-check form-switch form-switch-right form-switch-md form-switch-secondary'>
                            <Label className="text-muted d-flex justify-content-between" style={{width:'120px'}}>
                                <Input className='code-switch' type="checkbox" value="" checked={planPicker} onChange={(e) => changePlanPicker(e.target.checked)} />
                                Plan Picker
                            </Label>
                        </div>
                        <div className='form-check form-switch form-switch-right form-switch-md form-switch-secondary'>
                            <Label className="text-muted d-flex justify-content-between" style={{width:'120px'}}>
                                <Input className='code-switch' type="checkbox" value="" checked={planReport} onChange={(e) => changePlanReport(e.target.checked)} />
                                Plan Report
                            </Label>
                        </div>

                        <div className="d-flex justify-content-end">
                            {
                                isSaveing ? (
                                    <Button color="secondary" className="btn-load" outline>
                                        <span className="d-flex align-items-center">
                                            <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                            <span className="flex-grow-1 ms-2">
                                                Loading...
                                            </span>
                                        </span>
                                    </Button>
                                ) : (
                                    <Button
                                        color="secondary"
                                        type='button'
                                        onClick={(e) => handleSave(e)}
                                    >
                                        Save
                                    </Button>
                                )
                            }

                        </div>

                    </div>
                </ModalBody>
            ) : (
                <div className="pt-4 pb-4">
                    <Loader/>
                </div>
            )}
        </Modal>
    )
}