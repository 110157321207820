import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { changeGetAccessModalDisplay } from "../../store/modals/action";
import { useDispatch, useSelector } from "react-redux";
import { contactUs } from "../../api/market";
import { successnotify } from "../Message";
import { Link } from "react-router-dom";

const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const GetAccessModal = () => {
  const dispatch = useDispatch();
  const display = useSelector((state) => state.Modals.display);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  // const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errorFileds, setErrorFileds] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setErrorFileds([]);
    dispatch(changeGetAccessModalDisplay(false));
  };

  const submit = () => {
    const errors = [];
    if (!fname) errors.push("fname");
    if (!lname) errors.push("lname");
    if (!email || !reg.test(email)) errors.push("email");
    if (errors.length > 0) {
      setErrorFileds(errors);
      return;
    }

    setLoading(true);
    contactUs({
      first_name: fname,
      last_name: lname,
      company: company,
      email: email,
      question: message,
    }).then((res) => {
      setLoading(false);
      successnotify(
        "Thank you for your submission. We will reach out to you as soon as possible!"
      );
      localStorage.setItem("received_inquiry", "1");
      dispatch(changeGetAccessModalDisplay(false));
    });
  };

  return (
    <Modal isOpen={display} toggle={handleClose} size="lg" centered>
      <ModalHeader toggle={handleClose} />
      <ModalBody>
        <Row>
          <Col xs={12} md={5}>
            <h2
              className="lh-base"
              onClick={() => {
                successnotify(
                  "Thank you for your submission. We will reach out to you as soon as possible!"
                );
              }}
            >
              Access More Information
            </h2>
            <p className="text-muted mb-4">
              Contact us today to unlock your data:
            </p>
            <ul>
              <li>
                Gain access to comprehensive electricity and gas price dynamics
                across all USN utilities.
              </li>
              <li>
                Discover peak high and low prices for all USN utilities in the
                market.
              </li>
              <li>
                Compare market data with your own electricity supplier to
                understand your expected costs in real-time.
              </li>
            </ul>
          </Col>
          <Col xs={12} md={7}>
            <Form>
              <Row>
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label for="fname">First Name*</Label>
                    <Input
                      name="fname"
                      type="text"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                    {errorFileds.includes("fname") && (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        Please input first name
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label for="lname">Last Name*</Label>
                    <Input
                      name="lname"
                      type="text"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                    {errorFileds.includes("lname") && (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        Please input last name
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="email">Email*</Label>
                <Input
                  name="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errorFileds.includes("email") && (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    Please input a valid email
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="phone">Company</Label>
                <Input
                  name="phone"
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </FormGroup>
              {/* <FormGroup>
                <Label for="phone">Phone Number</Label>
                <Input
                  name="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormGroup> */}
              <FormGroup>
                <Label for="message">Leave Us a Message</Label>
                <Input
                  name="message"
                  type="textarea"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </FormGroup>
              <Button type="button" disabled={loading} onClick={submit}>
                Submit
              </Button>
            </Form>
            <p className="mt-4" style={{ color: "#666" }}>
              If you already have an account,{" "}
              <Link to="/login" style={{ color: "#865ce2" }}>
                Login
              </Link>{" "}
              to access all the information
            </p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default GetAccessModal;
