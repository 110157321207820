import React, { useEffect, useState } from "react";
import "../styles/myplan.css";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import Loader from "./Common/Loader";
import { NoResult } from "./Common/NoResult";
import { fetchUSNAverageRate } from "../api/usnMarket";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";

const USN_AverageRateGlance = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const [isLoading, setIsLoading] = useState(false);
  const [findData, setFindData] = useState(true);
  const [data, setData] = useState<any>();
  const currentUtility = useSelector(selectUtility);

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      fetchUSNAverageRate(
        currentLocation?.state_id,
        currentUtility.utility_id
      ).then((res) => {
        if (res.status === 1) {
          setData(res.response);
        } else {
          setFindData(false);
        }
        setIsLoading(false);
      });
    };

    if (currentUtility) {
      getData();
    }
  }, [currentUtility]);

  const AverageRate = () => {
    let {
      yesterday_average_rate,
      last_week_average_rate,
      last_month_average_rate,
      last_year_average_rate,
    } = data || {};
    return (
      <div className="list-averageRate">
        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{`${moment()
              .subtract(1, "days")
              .format("MM/DD/YYYY")}`}</h4>
          </CardHeader>
          <CardBody>
            <h5>{yesterday_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">7 Days Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_week_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">30 Days Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_month_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">1 Year Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_year_average_rate || 0}¢</h5>
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <div className="layout-width">
      <Card>
        <CardHeader className="align-items-center justify-content-between d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Market Average Rate</h4>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <Loader />
          ) : (
            <div>{findData ? <AverageRate /> : <NoResult />}</div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default USN_AverageRateGlance;
