import { CustomTooltip, CustomTooltipX, CustomTooltipY, CustomTooltipYLabel, CustomTooltipYVal } from "../Components/Styles/Chart.styled";
import { linearGradientDef } from '@nivo/core';

const theme = {
    axis: {
        ticks: {
            line: {
                stroke: '#bbb'
            },
            text: {
                fill: '#333'
            }
        },
        legend: {
            text: {
                fill: '#333',
                fontSize:20,
                fontFamily:'inherit',
            }
        }
    },
    grid: {
        line: {
            stroke: '#ddd',
            strokeDasharray: '3 3'
        }
    }
}


export const BaseChartProps: any = {
    /* --- BASE --- */
    margin: { top: 12, right: 40, bottom: 120, left: 60 },
    xScale: {
        type: 'time',
        format: '%m/%d/%Y',
        useUTC: false,
        precision: 'day',
    },
    xFormat: 'time:%m/%d/%Y',
    yScale: {
        min: 'auto',
        max: 'auto',
    },
    /* --- STYLE --- */
    curve: "linear",
    colors: { scheme: 'set1' },
    lineWidth: 2.5,
    theme: theme,
    enableArea: true,
    defs: [
        linearGradientDef('gradientA', [
            { offset: 0, color: 'inherit', opacity: .4 },
            { offset: 10, color: 'inherit', opacity: 0 }
        ])
    ],
    fill: [{ match: '*', id: 'gradientA' }],

    /* --- POINTS --- */
    enablePoints: false,
    // pointSize: 8,
    // pointBorderWidth: 2,
    // pointBorderColor: '#fff',
    // pointLabelYOffset: -12,

    /* --- GRID & AXES --- */
    enableGridX: false,
    axisBottom: {
        tickSize: 4,
        tickPadding: 16,
        // tickRotation: 35,
        // format: (val: string) => {
        //     const day = moment(val, 'MM/DD/YYYY');
        //     return day.date() % 2 === 0 ? day.format('MM/DD') : '';
        // },
        tickValues: 'every 2 days',
        format: '%b %d',
        legend:'',
        legendOffset: 60,
        legendPosition: 'middle'
    },
    axisLeft: {
        tickSize: 0,
        tickPadding: 16,
        legend: 'cent',
        legendOffset: -50,
        legendPosition: 'middle',
        tickValues: 5
    },

    /* --- INTERACTIVITY --- */
    useMesh: true,
    enableSlices: 'x',
    sliceTooltip: ({ slice }: { slice: any }) => {
        if (slice) {
            const { points } = slice || {};
            if (points) {
                let xVal = '';
                if (points[0] && points[0].data.xFormatted) {
                    xVal = points[0].data.xFormatted;
                }
                return (
                    <CustomTooltip>
                        <CustomTooltipX>{xVal}</CustomTooltipX>
                        {
                            points.map((val: any, idx: number) => (
                                <CustomTooltipY key={idx}>
                                    <CustomTooltipYLabel color={val.serieColor}>
                                        <i />
                                        <span>{val.serieId}</span>
                                    </CustomTooltipYLabel>
                                    <CustomTooltipYVal>{val.data.yFormatted}</CustomTooltipYVal>
                                </CustomTooltipY>
                            ))
                        }
                    </CustomTooltip>
                )
            }
        }
        return null
    },

    /* LEGENDS */
    legends: [{
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateY: 80,
        itemsSpacing: 8,
        itemDirection: 'top-to-bottom',
        symbolShape: 'circle',
        itemWidth: 200,
        itemHeight: 20,
        symbolSize: 14,
    }],

    /* MOTION */
    motionConfig: 'slow'
}