import React, { useEffect, useState } from "react";
import Loader from "./Common/Loader";
import "../styles/market.css";
import { fetchAvgPrice, fetchTerms } from "../api/market";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { MyChart } from "./MyChart";
import { errornotify } from "./Message";
import moment from "moment";
import { subtract, divide } from "../common/calculation";
import { DataSeriesTypes } from "../types";
// import AiSummary from "./AiSummary";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";

const startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
const endDate = moment().format("YYYY-MM-DD");

const PowerRateIndex = () => {
  const [price, setPrice] = useState("500");
  const [term, setTerm] = useState("");
  const [termList, setTermList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataString, setDataString] = useState("");
  const currentUtility = useSelector(selectUtility);

  const [filterValue, setFilterValue] = useState({
    price: 500,
    date: `${startDate}&${endDate}`,
    term: "",
    type: 3,
  });
  const [data, setData] = useState<DataSeriesTypes[]>([]);
  const [openTerms, setOpenTerms] = useState(false);

  const getAvgPrice = async () => {
    setIsLoading(true);

    const res = await fetchAvgPrice({
      utility_id: currentUtility.utility_id,
      ...filterValue,
    });
    let resData = [];
    if (res.status === 1) {
      resData = res.response.average_price;
    } else {
      setIsLoading(false);
      // errornotify(res.message);
      return;
    }

    const avgData = resData.map((item: any) => {
      if (item.average_price !== 0) {
        return { x: item.date, y: +item.average_price };
      } else {
        return { x: item.date, y: null };
      }
    });
    getMonthData(avgData);

    if (res?.response?.average_price) {
      setDataString(`
                ##24 Month Average Rate Change
                ${JSON.stringify(res.response.average_price)}
            `);
    } else {
      setDataString("");
    }
  };

  const getMonthData = (val: any) => {
    let lastYears = val.slice(0, 24);
    let thisYears = val.slice(12, 36);

    thisYears.sort((a: any, b: any) => {
      return b - a;
    });

    // lastYears.sort((a: any, b: any) => {
    //     let _a = a.x.slice(0, 2);
    //     let _b = b.x.slice(0, 2);

    //     return _a - _b;
    // })

    // const thisYearData = thisYears.map((item: any) => {
    //     return { x: getMonth(item.x.slice(0, 2)), y: item.y }
    // })

    let _data = thisYears.map((item: any, index: any) => {
      var num = subtract(item.y, lastYears[index].y, 2);
      return { x: item.x, y: divide(num, lastYears[index].y, 2) };
    });

    setData([
      {
        id: "Power Rate Index",
        data: _data.filter((i: any) => i.y),
      },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!currentUtility) return;
    getAvgPrice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, currentUtility]);

  useEffect(() => {
    const getTerms = async () => {
      const res = await fetchTerms(currentUtility.utility_id);
      if (res.status !== 1) {
        return;
      }
      setTermList(res.response);
    };

    if (currentUtility) {
      getTerms();
    }
  }, [currentUtility]);

  const changeUsage = (val: string) => {
    setPrice(val);
    setFilterValue({
      ...filterValue,
      price: +val,
    });
  };

  const changeTerm = (val: any) => {
    setTerm(val);
    setFilterValue({
      ...filterValue,
      term: val,
    });
  };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "600px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Rate % Change by TDU/Usage/Term
          </h4>
        </CardHeader>
        {/* <AiSummary data={dataString} /> */}
        <CardBody>
          <div className="d-flex flex-wrap gap-2 mb-3">
            {["500", "1000", "2000"].map((val: string, idx: number) => (
              <Button
                onClick={() => changeUsage(val)}
                color={price === val ? "secondary" : "light"}
                key={idx}
              >
                {val} kWh
              </Button>
            ))}
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Dropdown
                isOpen={openTerms}
                toggle={() => setOpenTerms(!openTerms)}
              >
                <DropdownToggle tag="button" className="btn btn-light">
                  {term ? `${term} Month` : "All Term"}{" "}
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu style={{ overflow: "scroll", height: "20rem" }}>
                  <DropdownItem key={"allterm"} onClick={() => changeTerm("")}>
                    All Term
                  </DropdownItem>
                  {termList.map((item: any, index: any) => (
                    <DropdownItem key={index} onClick={() => changeTerm(item)}>
                      {item} Month
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <MyChart
                type="powerRateIndex"
                title="24-Month Rate Change"
                footer="Month/Year"
                data={data[0]?.data.length ? data : []}
              />
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default PowerRateIndex;
