import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { changeUtility } from "../../store/actions";
import { selectUtility, selectUtilityList } from "../../store/utility/selector";

const UtilityDropdown = () => {
  const dispatch = useDispatch();
  const currentUtility = useSelector(selectUtility);
  const utilityList = useSelector(selectUtilityList);

  const electricityUtilityList = utilityList?.electricity || [];

  const [isUtilityDropdown, setIsUtilityDropdown] = useState(false);
  const toggleUtilityDropdown = () => {
    setIsUtilityDropdown(!isUtilityDropdown);
  };

  if (electricityUtilityList?.length === 0) return null;
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isUtilityDropdown}
        toggle={toggleUtilityDropdown}
        className="header-item"
      >
        <DropdownToggle tag="button" type="button" className="btn dropdown-btn">
          <span className="d-flex align-items-center">
            <span className="text-start">
              {currentUtility?.utility_name}
            </span>
            <i className="ri-arrow-down-s-line ms-1" />
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {electricityUtilityList.map((item) => (
            <DropdownItem
              key={item.utility_id}
              onClick={() => {
                if (currentUtility?.utility_id === item.utility_id) return;
                dispatch(changeUtility(item));
                window.localStorage.setItem("utility", JSON.stringify(item));
              }}
              className="d-flex align-items-center justify-content-between"
            >
              <span>{item.utility_name}</span>
              {item.utility_id === currentUtility?.utility_id && (
                <i className="ri-check-line me-1" />
              )}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default UtilityDropdown;
