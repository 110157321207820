import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { fetchEflDoc } from '../api/myplan'
import { eflUrlDisplay } from '../store/actions'
import { errornotify } from './Message'

export const UrlModal = () => {
    const dispatch = useDispatch()
    const { openUrl } = useSelector((state: any) => ({
        openUrl: state.Layout.openUrl
    }))
    const { url } = useSelector((state: any) => ({
        url: state.Layout.url
    }))
    const handleClose = () => {
        dispatch(eflUrlDisplay(false))
    }
    return (
        <Modal size="lg" isOpen={openUrl} toggle={handleClose} centered>
            <ModalHeader id="myLargeModalLabel" toggle={handleClose}></ModalHeader>
            <ModalBody>
                <iframe src={url} title='EFL' style={{ width: '100%', minHeight: '50rem' }}></iframe>
            </ModalBody>
        </Modal>
    )
}

export const jumpTo = (plan_id: any, pd_id: any) => {
    fetchEflDoc(plan_id, pd_id).then((res) => {
        if (res.status === 1) {
            const w: any = window.open('_blank');
            w.location.href = res.response
        } else {
            errornotify(res.message);
        }
    });
}

