import {
  CHANGE_UTILITY,
  SET_UTILITY,
  FETCH_UTILITY_REQUEST,
  FETCH_UTILITY_SUCCESS,
  FETCH_UTILITY_FAILURE,
} from "./actionType";

export const changeUtility = (utility) => ({
  type: CHANGE_UTILITY,
  payload: utility,
});

export const setUtility = (utility) => ({
  type: SET_UTILITY,
  payload: utility,
});

export const fetchUtilityRequest = () => ({ type: FETCH_UTILITY_REQUEST });

export const fetchUtilitySuccess = (utilityList) => ({
  type: FETCH_UTILITY_SUCCESS,
  payload: utilityList,
});

export const fetchUtilityFailure = (error) => ({
  type: FETCH_UTILITY_FAILURE,
  payload: error,
});
