export function isInteger(obj:any) {
    return Math.floor(obj) === obj;
}

export function toInteger(floatNum:number) {
    var ret = { times: 1 as any, num: 0 as any}
    var isNegative = floatNum < 0
    if (isInteger(floatNum)) {
        ret.num = floatNum
        return ret
    }
    var strfi:any = floatNum + ''
    var dotPos:any = strfi.indexOf('.')
    var len:any = strfi.substr(dotPos + 1).length
    var times:any = Math.pow(10, len)
    var intNum:any = parseInt((Math.abs(floatNum) * times + 0.5).toString(), 10)
    ret.times = times
    if (isNegative) {
        intNum = -intNum
    }
    ret.num = intNum
    return ret
}

export function toFixed(num:any, s:any) {
    var times = Math.pow(10, s);
    var des :any = num * times + 0.5;
    des = (parseInt(des, 10) / times) + '';

    var pos_decimal = des.indexOf('.');
    if (pos_decimal > 0) {
        while (des.length <= pos_decimal + s) {
            des += '0';
        }
    }

    return des;
}

function operation(a:any, b:any, dig:any, op:any) {
    var o1 = toInteger(a)
    var o2 = toInteger(b)
    var n1 = o1.num
    var n2 = o2.num
    var t1 = o1.times
    var t2 = o2.times
    var max = t1 > t2 ? t1 : t2
    var result = null
    switch (op) {
        case 'add':
            if (t1 === t2) { 
                result = n1 + n2
            } else if (t1 > t2) { 
                result = n1 + n2 * (t1 / t2)
            } else {
                result = n1 * (t2 / t1) + n2
            }
            return toFixed(result / max, dig)
        case 'subtract':
            if (t1 === t2) {
                result = n1 - n2
            } else if (t1 > t2) {
                result = n1 - n2 * (t1 / t2)
            } else {
                result = n1 * (t2 / t1) - n2
            }
            return toFixed(result / max, dig)
        case 'multiply':
            result = (n1 * n2) / (t1 * t2)
            return toFixed(result, dig)
        case 'divide':
            if (n2 === 0)
                result = 0
            else
                result = (n1 / n2) * (t2 / t1)
            return toFixed(result, dig)
    }
}

/**
 * +
 */
export function add(a:any, b:any, s:any) {
    return operation(a, b, (s | 2), 'add')
}

/**
 * -
 */
export function subtract(a:any, b:any, s:any) {
    return operation(a, b, (s | 2), 'subtract')
}

/**
 * *
 */
export function multiply(a:any, b:any, s:any) {
    return operation(a, b, (s | 2), 'multiply')
}

/**
 * 
 */
export function divide(a:any, b:any, s:any) {
    return operation(a, b, (s | 2), 'divide')
}
