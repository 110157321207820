import { Tooltip as ReactTooltip } from "react-tooltip";

interface TooltipProps {}

const MyTooltip: React.FC<TooltipProps> = ({ children }) => {
  const randomNum = Math.floor(Math.random() * 1000);

  return (
    <>
      <a
        data-tooltip-id={`tooltip-${randomNum}`}
        style={{
          background: "#aaa",
          color: "#fff",
          display: "inline-flex",
          width: "24px",
          height: "24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          flexShrink: 0,
        }}
      >
        <i className="bx bx-question-mark" />
      </a>
      <ReactTooltip
        clickable
        id={`tooltip-${randomNum}`}
        className="tooltip-content"
      >
        <div style={{ padding: "8px 4px" }}>
          <div>{children}</div>
        </div>
      </ReactTooltip>
    </>
  );
};

export default MyTooltip;
