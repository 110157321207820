/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-east-2',
    aws_cognito_region: 'us-east-2',
    aws_user_pools_id: 'us-east-2_sTXcVV4cC',
    aws_user_pools_web_client_id: '2f3qaqno4o6faa90j0tvjos2fe',
    oauth: {
        domain: 'pri.auth.us-east-2.amazoncognito.com',
    },
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OPTIONAL',
    aws_cognito_mfa_types: ['SMS', 'TOTP'],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            'REQUIRES_LOWERCASE',
            'REQUIRES_UPPERCASE',
            'REQUIRES_NUMBERS',
            'REQUIRES_SYMBOLS',
        ],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
}

export default awsmobile
