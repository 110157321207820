import { useEffect, useState } from "react";
import "../styles/myplan.css";
import { fetchPlanRank, fetchRankDetail } from "../api/myplan";
import moment from "moment";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Loader from "./Common/Loader";
import { MyChart } from "./MyChart";
import { DataPointTypes } from "../types";
import { NoResult } from "./Common/NoResult";
import { jumpTo } from "./UrlModal";
// import AiSummary from "./AiSummary";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";

interface PlanRankDetail {
  base_rate: string;
  date: string;
  pd_id: string;
  plan_id: string;
  rank: number;
}

interface ChartSeries {
  [key: string]: DataPointTypes;
}

type PlanRank = any;

const today = moment().format("YYYY-MM-DD");

const MyPlanRank = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rankTableData, setRankTableData] = useState<PlanRank>([]);
  const [filterValue, setFilterValue] = useState({
    price: "500",
    created_ts: today,
  });
  const [chartSeries, setChartSeries] = useState<ChartSeries>({});
  const [dataStringObj, setDataStringObj] = useState({});
  const currentUtility = useSelector(selectUtility);

  useEffect(() => {
    const getPlanRank = async () => {
      setIsLoading(true);
      setDataStringObj({});

      fetchPlanRank({
        utility_id: currentUtility.utility_id,
        ...filterValue,
      }).then((res) => {
        setIsLoading(false);
        if (res && res.status === 1) {
          setRankTableData(res.response);
        }
      });
    };

    if (currentUtility) {
      getPlanRank();
    }
  }, [filterValue, currentUtility]);

  useEffect(() => {
    if (rankTableData.length === 0) return;

    for (let item of rankTableData) {
      getRankData(item.term, filterValue.price, "", item.utility_name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankTableData]);

  const getRankData = (
    term: string,
    price: string,
    source: string,
    utility_name: string
  ) => {
    fetchRankDetail(
      currentUtility.utility_id,
      term,
      price,
      source,
      filterValue.created_ts
    ).then((res) => {
      let each: DataPointTypes[] = [];
      if (res && res.status === 1) {
        const { response } = res;
        response.forEach((data: PlanRankDetail) => {
          if (
            data.rank !== 0 &&
            moment(data.date, "MM/DD/YYYY").isAfter(
              moment(filterValue.created_ts, "YYYY-MM-DD").subtract(1, "month")
            )
          ) {
            each.push({
              x: data.date,
              y: data.rank,
              detail: data,
            });
          }
        });
      }
      const _dataString = `
                ##${utility_name} Rand Trend - Term: ${term}
                ${JSON.stringify(
                  res?.response?.filter((item: any) => item.rank !== 0)
                )}
            `;
      setDataStringObj((prevState) =>
        Object.assign({}, prevState, { [term]: _dataString })
      );
      setChartSeries((prevState) =>
        Object.assign({}, prevState, { [term]: each })
      );
    });
  };

  const changePrice = (val: any) => {
    setFilterValue(Object.assign({}, filterValue, { price: val }));
  };

  const changeDate = (val: any) => {
    setFilterValue(
      Object.assign({}, filterValue, {
        created_ts: moment(val).format("YYYY-MM-DD"),
      })
    );
  };

  const displayRaw = () => {
    if (isLoading && rankTableData.length === 0) return <Loader />;
    if (rankTableData.length > 0) {
      return rankTableData.map((item: any, idx: number) => {
        if (item.term === "48" || item.term === "60") {
          return <></>;
        } else {
          return (
            <Row
              key={idx}
              data={item}
              chartData={
                chartSeries[item.term] ? chartSeries[item.term] : ([] as any)
              }
              isLoading={isLoading}
              handleChangeSource={(term: string, source: string) => {
                getRankData(term, filterValue.price, source, item.utility_name);
              }}
              dataString={(dataStringObj as any)[item.term]}
            />
          );
        }
      });
    }
    return <NoResult />;
  };

  return (
    <div className="layout-width">
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">My Plan Rank</h4>
        </CardHeader>
        <CardBody>
          <div className="mb-4 d-flex justify-content-between">
            <div className="d-flex flex-wrap gap-2">
              {["500", "1000", "2000"].map((val, idx) => (
                <Button
                  key={idx}
                  onClick={() => changePrice(val)}
                  color={filterValue.price === val ? "secondary" : "light"}
                >
                  {val} kWh
                </Button>
              ))}
            </div>
            <Flatpickr
              className="rank_datepicker"
              value={filterValue.created_ts}
              options={{
                dateFormat: "Y-m-d",
                defaultDate: [today],
                minDate: moment().startOf("year").format("YYYY-MM-DD"),
                maxDate: today,
                allowInput: true,
              }}
              onChange={([newValue]: any) => {
                changeDate(newValue);
              }}
            />
          </div>
          <Flatpickr
            className="mobile_rank_datepicker"
            value={filterValue.created_ts}
            options={{
              dateFormat: "Y-m-d",
              defaultDate: [today],
              minDate: moment().startOf("year").format("YYYY-MM-DD"),
              maxDate: today,
              allowInput: true,
            }}
            onChange={([newValue]: any) => {
              changeDate(newValue);
            }}
          />
          {displayRaw()}
        </CardBody>
      </Card>
    </div>
  );
};

const Row = ({
  data,
  isLoading,
  chartData,
  handleChangeSource,
  dataString,
}: {
  data: any;
  isLoading: boolean;
  chartData: DataPointTypes[];
  handleChangeSource: Function;
  dataString: string;
}) => {
  const [selectedSource, setSelectedSource] = useState("market");

  const { term, source } = data;

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between fs-16">
        <div>Term: {term}</div>
      </CardHeader>
      {/* <AiSummary data={dataString} /> */}
      <CardBody className="row">
        <div className="col-lg-7 col-12">
          {isLoading ? (
            <Loader />
          ) : chartData.length > 0 ? (
            <>
              <h5 className="mt-3 text-center">
                Rank Trend by {selectedSource.toLocaleUpperCase()}
              </h5>
              <MyChart
                type="rankdetail"
                // title="Rank Trend"
                data={[{ id: `Term ${term}`, data: chartData }]}
                size="xs"
              />
            </>
          ) : (
            <NoResult />
          )}
        </div>
        <div className="col-lg-5 col-12 rank_trend_market_table">
          <Table className="table-borderless">
            <thead>
              <tr>
                <th></th>
                <th>Source</th>
                <th>Lowest Rate</th>
                <th className="text-end">Rank</th>
              </tr>
            </thead>
            <tbody>
              {source &&
                Object.keys(source).map((idx) => {
                  if (!source[idx].rate) return null;
                  return (
                    <tr
                      key={idx}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedSource === idx ? "#f7f7f7" : "transparent",
                      }}
                      onClick={() => {
                        if (selectedSource === idx) return;
                        setSelectedSource(idx);
                        handleChangeSource(
                          term,
                          idx === "market" ? "" : source[idx].source
                        );
                      }}
                    >
                      <td className="pe-0">
                        {selectedSource === idx && (
                          <i className="fs-20 me-2 bx bx-chevrons-right text-secondary" />
                        )}
                      </td>
                      <td>
                        <span className="text-uppercase">{idx}</span>
                      </td>
                      <td>
                        <span>{source[idx].plan_name}</span>
                        <br />
                        <span>
                          <b>{source[idx].rate + "¢"}</b>
                          {source[idx].plan_id && (
                            <Button
                              onClick={() =>
                                jumpTo(source[idx].plan_id, source[idx].pd_id)
                              }
                              outline
                              rel="noreferrer"
                              className="btn btn-soft-secondary btn-sm ms-2"
                            >
                              EFL
                            </Button>
                          )}
                        </span>
                      </td>
                      <td className="text-end">
                        <b>{source[idx].rank}</b>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default MyPlanRank;
