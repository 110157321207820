import {
  CHANGE_UTILITY,
  FETCH_UTILITY_REQUEST,
  FETCH_UTILITY_SUCCESS,
  FETCH_UTILITY_FAILURE,
  SET_UTILITY,
} from "./actionType";

const INIT_UTILITY = {
  currentUtility: null,
  utilityList: {
    electricity: [],
    gas: []
  },
  isLoading: false,
  error: null,
};

const Utility = (state = INIT_UTILITY, action) => {
  switch (action.type) {
    case CHANGE_UTILITY:
      return { ...state, currentUtility: action.payload };

    case SET_UTILITY:
      return { ...state, currentUtility: action.payload };

    case FETCH_UTILITY_REQUEST:
      return { ...state, isLoading: true, error: null };

    case FETCH_UTILITY_SUCCESS:
      return { ...state, isLoading: false, utilityList: action.payload };

    case FETCH_UTILITY_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

export default Utility;
