import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { FETCH_UTILITY_REQUEST, CHANGE_UTILITY } from "./actionType";
import { fetchUtilitySuccess, fetchUtilityFailure, setUtility } from "./action";
import { selectLocation } from "../location/selector";
import { fetchUtilityId } from "../../api/market";
import { fetchAllUSNUtilityId, fetchUSNUtilityId } from "../../api/usnMarket";
import { Auth } from "aws-amplify";

function* fetchUtilitySaga() {
  try {
    const currentLocation = yield select(selectLocation);
    if (!currentLocation) return;

    yield put(
      fetchUtilitySuccess({
        electricity: [],
        gas: [],
      })
    );
    yield put(setUtility(null));

    const user = yield Auth.currentUserInfo();

    const isTX = currentLocation?.state_id === "TX";
    const res = isTX
      ? yield call(fetchUtilityId)
      : user
      ? yield call(fetchUSNUtilityId, currentLocation?.state_id)
      : yield call(fetchAllUSNUtilityId, currentLocation?.state_id);

    let finalUtilityList = {
      electricity: [],
      gas: [],
    };

    if (isTX) {
      finalUtilityList.electricity = res?.response || [];
    } else {
      finalUtilityList.electricity = res?.response?.electricity || [];
      finalUtilityList.gas = res?.response?.gas || [];
    }

    yield put(fetchUtilitySuccess(finalUtilityList));

    const utility = yield call(() => window.localStorage.getItem("utility"));
    let defaultUtility = finalUtilityList?.electricity?.[0] || null;

    if (utility) {
      const utilityData = JSON.parse(utility);
      const findUtility = [
        ...finalUtilityList.electricity,
        ...finalUtilityList.gas,
      ].find((utility) => utility.utility_id === utilityData?.utility_id);
      if (findUtility) {
        defaultUtility = findUtility;
      }
    }

    // console.log("finalUtilityList", finalUtilityList);
    // console.log("defaultUtility", defaultUtility);

    yield put(setUtility(defaultUtility));
  } catch (error) {
    console.log(error);
    yield put(fetchUtilityFailure(error.message));
  }
}

function* changeUtilitySaga(action) {
  const newUtility = action.payload;
  yield put(setUtility(newUtility));
  yield call(() => {
    window.localStorage.setItem("utility", JSON.stringify(newUtility));
  });
}

function* watchFetchUtility() {
  yield takeLatest(FETCH_UTILITY_REQUEST, fetchUtilitySaga);
}

function* watchChangeUtility() {
  yield takeLatest(CHANGE_UTILITY, changeUtilitySaga);
}

export default function* utilitySaga() {
  yield all([watchFetchUtility(), watchChangeUtility()]);
}
