import React from "react";
import { Switch, Route } from "react-router-dom";
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
import { authProtectedRoutes, publicRoutes, otherRoutes } from "./allRoutes";
import { AuthProtected, AccessRoute } from "./AuthProtected";

const Index = () => {
    const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
    const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
    const availableOtherRoutesPath = otherRoutes.map((r) => r.path);
    
    return (
        <React.Fragment>
            <Switch>
                <Route path={availableAuthRoutesPath}>
                    <AuthProtected>
                        <VerticalLayout>
                            <Switch>
                                {
                                    authProtectedRoutes.map((route, idx) => (
                                        <AccessRoute
                                            path={route.path}
                                            component={route.component}
                                            key={idx}
                                            exact={true}
                                        />
                                    ))
                                }
                            </Switch>
                        </VerticalLayout>
                    </AuthProtected>
                </Route>
                <Route path={availableOtherRoutesPath}>
                    <NonAuthLayout>
                        <Switch>
                            {
                                otherRoutes.map((route, idx) => (
                                    <Route
                                        path={route.path}
                                        component={route.component}
                                        key={idx}
                                        exact={true}
                                    />
                                ))
                            }
                        </Switch>
                    </NonAuthLayout>
                </Route>
                <Route path={availablePublicRoutesPaths}>
                    <VerticalLayout>
                        <Switch>
                            {
                                publicRoutes.map((route, idx) => (
                                    <Route
                                        path={route.path}
                                        component={route.component}
                                        key={idx}
                                        exact={true}
                                    />
                                ))
                            }
                        </Switch>
                    </VerticalLayout>
                </Route>
            </Switch>
        </React.Fragment>
    )
}

export default Index;