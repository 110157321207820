import React, { useEffect, useState } from "react";
import "../styles/myplan.css";
import { fetchAverageRateList } from "../api/market";
import moment from "moment";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import Loader from "./Common/Loader";
import { NoResult } from "./Common/NoResult";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";

const LoginMarketAverageRate = () => {
  const [price, setPrice] = useState("500");
  const [isLoading, setIsLoading] = useState(false);
  const [findData, setFindData] = useState(true);
  const [data, setData] = useState<any>();
  const [filterValue, setFilterValue] = useState({
    utility_id: "",
    price: 500,
  });
  const currentUtility = useSelector(selectUtility);

  useEffect(() => {
    if (!currentUtility) return;
    getData();
  }, [filterValue, currentUtility]);

  const getData = () => {
    setIsLoading(true);
    fetchAverageRateList(filterValue).then((res) => {
      if (res.status === 1) {
        setFindData(true);
        setData(res.response);
      } else {
        setFindData(false);
      }
      setIsLoading(false);
    });
  };

  const AverageRate = () => {
    let {
      yesterday_average_rate,
      last_week_average_rate,
      last_month_average_rate,
      last_year_average_rate,
    } = data || {};
    return (
      <div className="list-averageRate">
        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{`${moment()
              .subtract(1, "days")
              .format("MM/DD/YYYY")}`}</h4>
          </CardHeader>
          <CardBody>
            <h5>{yesterday_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">7 Days Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_week_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">30 Days Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_month_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">1 Year Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_year_average_rate || 0}¢</h5>
          </CardBody>
        </Card>
      </div>
    );
  };

  const changeUsage = (val: string) => {
    setPrice(val);
    setFilterValue({
      ...filterValue,
      price: +val,
    });
  };

  return (
    <div className="layout-width">
      <Card>
        <CardHeader className="align-items-center justify-content-between d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Average Rate</h4>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-wrap gap-2 mb-3">
            {["500", "1000", "2000"].map((val: string, idx: number) => (
              <Button
                onClick={() => changeUsage(val)}
                color={price === val ? "secondary" : "light"}
                key={idx}
              >
                {val} kWh
              </Button>
            ))}
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div>{findData ? <AverageRate /> : <NoResult />}</div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default LoginMarketAverageRate;
