import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUtility } from "../../store/utility/selector";
import { fetchMyProviderInfo } from "../../api/market";
import { fetchUSNMyProviderInfo } from "../../api/usnMarket";

interface MyProviderInfoProps {
  plan_nums: number;
  provider_id: string;
  provider_name: string;
  time: string;
}

const useMyProviderInfo = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const currentUtility = useSelector(selectUtility);

  const [data, setData] = useState<null | MyProviderInfoProps>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const res =
        currentLocation?.state_id === "TX"
          ? await fetchMyProviderInfo(currentUtility?.utility_id)
          : await fetchUSNMyProviderInfo(
              currentLocation?.state_id,
              "1",
              currentUtility?.utility_id
            );

      if (res?.error_code === 500 || res.status !== 1) {
        setData(null);
      } else {
        setData(res.response);
      }
    };

    if (currentLocation && currentUtility) {
      getData();
    }
  }, [currentLocation, currentUtility]);

  return { data, loading };
};

export default useMyProviderInfo;
