import { api } from "../config";
import { getToken } from "./common";

export const fetchPlanRank = async (val) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', val.utility_id)
    formdata.append('price', val.price)
    formdata.append('created_ts', val.created_ts)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}MyPlan/my_plan_rank`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchPlanList = async (val) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', val.utility_id)

    if (+val.term) formdata.append('term', +val.term)
    formdata.append('created_ts', val.created_ts)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}MyPlan/my_plan_list`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const exportMyPlanList = async (value) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', value.utility_id)
    formdata.append('created_ts', value.created_ts)
    if (+value.term) formdata.append('term', +value.term)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
        // responseType: 'blob',
    }
    const url = `${api.API_URL}MyPlan/export_my_plan_list`
    const response = await fetch(url, requestOptions)
    return response
}

export const fetchMyPlanDetail = async (plan_id, created_ts, date) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('plan_id', plan_id)
    formdata.append('created_ts', created_ts)
    formdata.append('date', date)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}MyPlan/my_plan_list_detail`
    const response = await fetch(url, requestOptions)
    return response.json()
}
export const fetchBadPlans = async () => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        // body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}MyPlan/my_plan_issues`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchEflDoc = async (plan_id, pd_id) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('plan_id', plan_id)
    formdata.append('pd_id', pd_id)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}MyPlan/efl_document`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchRankDetail = async (utility_id, term, price, source, date) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', utility_id)
    formdata.append('term', term)
    formdata.append('price', price)
    formdata.append('date', date)
    if (source) {
        formdata.append('source', source)
    }
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}MyPlan/my_plan_rank_detail`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchKWHPrice = async (plan_id, pd_id) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('plan_id', plan_id)
    formdata.append('pd_id', pd_id)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}MyPlan/price_forecast`
    const response = await fetch(url, requestOptions)
    return response.json()
}