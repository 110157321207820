import { BarDatum, ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine, Serie } from "@nivo/line";
import {
  ChartBlock,
  ChartFooter,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipX,
  CustomTooltipY,
  CustomTooltipYItem,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "./Styles/Chart.styled";
import Loader from "./Common/Loader";
import { NoResult } from "./Common/NoResult";

interface MyBarChartProps {
  type: "PeakTimeChart" | "AllPTCRateBarChart";
  title?: string;
  data: BarDatum[] | Serie[];
  size?: string;
  loading?: boolean;
  footer?: string;
}

const colors = [
  "#37A2DA",
  "#32C5E9",
  "#67E0E3",
  "#9FE6B8",
  "#FFDB5C",
  "#ff9f7f",
  "#fb7293",
  "#E062AE",
  "#E690D1",
  "#e7bcf3",
  "#9d96f5",
  "#8378EA",
  "#96BFFF",
];

export const MyBarChart: React.FC<MyBarChartProps> = ({
  type,
  title,
  data,
  size,
  loading,
  footer,
}) => {
  const getTspanGroups = (value: string) => {
    const words = value.split(" ");
    const maxLines = 2;
    const maxLineLength = 12;

    type linesAcc = {
      lines: string[];
      currLine: string;
    };

    //reduces the words into lines of maxLineLength
    const assembleLines: linesAcc = words.reduce(
      (acc: linesAcc, word: string) => {
        //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
        if (
          (word + acc.currLine).length > maxLineLength &&
          acc.currLine !== ""
        ) {
          return {
            lines: acc.lines.concat([acc.currLine]),
            currLine: word,
          };
        }
        //otherwise add the word to the current line
        return {
          ...acc,
          currLine: acc.currLine + " " + word,
        };
      },
      { lines: [], currLine: "" }
    );

    //add the ending state of current line (the last line) to lines
    const allLines = assembleLines.lines.concat([assembleLines.currLine]);

    //for now, only take first 2 lines due to tick spacing and possible overflow
    const lines = allLines.slice(0, maxLines);
    let children: JSX.Element[] = [];
    let dy = 0;

    lines.forEach((lineText, i) => {
      children.push(
        <tspan x={0} dy={dy} key={i}>
          {
            // if on the second line, and that line's length is within 3 of the max length, add ellipsis
            1 === i && allLines.length > 2
              ? lineText.slice(0, maxLineLength - 3) + "..."
              : lineText
          }
        </tspan>
      );
      //increment dy to render next line text below
      dy += 15;
    });

    return children;
  };

  const chartContent = () => {
    if (loading) return <Loader />;

    if (data.length > 0) {
      if (type === "PeakTimeChart") {
        const findMaxMin = (data: any) => {
          let max = -Infinity;
          let min = Infinity;

          data.forEach((item: any) => {
            item.data.forEach((point: any) => {
              if (point.y > max) max = point.y;
              if (point.y < min) min = point.y;
            });
          });

          return { max, min };
        };

        const minMaxResult = findMaxMin(data);

        const chartProps: any = {
          colors: { datum: "color" },
          margin: { top: 12, right: 40, bottom: 100, left: 60 },
          xScale: { type: "point" },
          yScale: {
            type: "linear",
            min: minMaxResult.min - 10,
            max: minMaxResult.max + 10,
            // stacked: true,
            reverse: false,
          },
          axisTop: null,
          axisRight: null,
          lineWidth: 2.5,
          curve: "linear",
          axisBottom: {
            tickSize: 4,
            tickPadding: 16,
            legend: "",
            legendOffset: 60,
            legendPosition: "middle",
          },
          axisLeft: {
            tickSize: 0,
            tickPadding: 16,
            legend: "cent",
            legendOffset: -50,
            legendPosition: "middle",
            tickValues: 5,
          },
          enablePoints: false,
          pointColor: { theme: "background" },
          pointBorderWidth: 2,
          pointBorderColor: { from: "serieColor" },
          pointLabelYOffset: -12,
          useMesh: true,
          enableGridX: false,
          theme: {
            axis: {
              ticks: {
                line: {
                  stroke: "#bbb",
                },
                text: {
                  fill: "#333",
                },
              },
              legend: {
                text: {
                  fill: "#333",
                  fontSize: 20,
                  fontFamily: "inherit",
                },
              },
            },
            grid: {
              line: {
                stroke: "#ddd",
                strokeDasharray: "3 3",
              },
            },
          },
          legends: [
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateY: 80,
              itemsSpacing: 8,
              itemDirection: "top-to-bottom",
              symbolShape: "circle",
              itemWidth: 200,
              itemHeight: 20,
              symbolSize: 14,
            },
          ],
          enableSlices: "x",
          sliceTooltip: ({ slice }: { slice: any }) => {
            if (slice) {
              const { points } = slice || {};
              if (points) {
                let xVal = "";
                if (points[0] && points[0].data.xFormatted) {
                  xVal = points[0].data.xFormatted;
                }
                return (
                  <CustomTooltip>
                    <CustomTooltipX>{xVal}</CustomTooltipX>
                    {points.map((val: any, idx: number) => (
                      <CustomTooltipY style={{ display: "flex" }} key={idx}>
                        <CustomTooltipYLabel color={val.serieColor}>
                          <i />
                          <span>{val.serieId}</span>
                        </CustomTooltipYLabel>
                        <CustomTooltipYVal>
                          {val.data.yFormatted}¢
                        </CustomTooltipYVal>
                      </CustomTooltipY>
                    ))}
                  </CustomTooltip>
                );
              }
            }
            return null;
          },
        };

        return <ResponsiveLine data={data as Serie[]} {...chartProps} />;
      }

      if (type === "AllPTCRateBarChart") {
        const chartProps: any = {
          keys: ["ptc"],
          indexBy: "utility_name",
          margin: { top: 0, right: 40, bottom: 60, left: 60 },
          padding: 0.7,
          valueScale: { type: "linear" },
          indexScale: { type: "band", round: true },
          colors: (bar: any) => colors[bar.index] || colors[0],
          borderColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          axisTop: null,
          axisRight: null,
          axisBottom: {
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Utility",
            legendPosition: "middle",
            legendOffset: 50,
            // truncateTickAt: 0,
            renderTick: ({
              opacity,
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y,
            }: {
              opacity: any;
              textAnchor: any;
              textBaseline: any;
              textX: any;
              textY: any;
              value: any;
              x: any;
              y: any;
            }) => {
              return (
                <g transform={`translate(${x},${y})`} style={{ opacity }}>
                  <text
                    alignmentBaseline={textBaseline}
                    style={{
                      fill: "rgb(51, 51, 51)",
                      fontFamily: "sans-serif",
                      fontSize: "11px",
                    }}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                  >
                    {getTspanGroups(value)}
                  </text>
                </g>
              );
            },
          },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Rate",
            legendPosition: "middle",
            legendOffset: -40,
            // truncateTickAt: 0,
          },
          enableLabel: false,
          labelSkipWidth: 12,
          labelSkipHeight: 12,
          labelTextColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          tooltip: (bar: any) => {
            return (
              <CustomTooltip>
                <CustomTooltipYItem>
                  <CustomTooltipYLabel color={bar.color}>
                    <i />
                    <span>{bar.indexValue}</span>
                  </CustomTooltipYLabel>
                  <CustomTooltipYVal>{`${bar.value}¢`}</CustomTooltipYVal>
                </CustomTooltipYItem>
              </CustomTooltip>
            );
          },
          role: "application",
          ariaLabel: "All PTC Rate",
          barAriaLabel: (e: any) =>
            e.id + ": " + e.formattedValue + " - " + e.indexValue,
        };

        return <ResponsiveBar data={data as BarDatum[]} {...chartProps} />;
      }

      return null;
    }
    return <NoResult />;
  };

  return (
    <ChartBlock>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper xs={size && size === "xs" ? true : false}>
        {chartContent()}
      </ChartWrapper>
      {footer && <ChartFooter>{footer}</ChartFooter>}
    </ChartBlock>
  );
};
