/** USN - Utility Insight Page */
import { Helmet } from "react-helmet";
import USN_PTCRateTrends from "../Components/USN_PTCRateTrends";
import USN_PeaktimeRateTrends from "../Components/USN_PeaktimeRateTrends";

export const StateInsight = () => {
  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex</title>
      </Helmet>
      <USN_PTCRateTrends />
      <USN_PeaktimeRateTrends />
    </div>
  );
};
