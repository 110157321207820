import CountUp from "react-countup";
import Loader from "./Common/Loader";
import { getLogoSrc } from "../helpers/func";
import { useSelector } from "react-redux";
import useMyProviderInfo from "./Hooks/useMyProviderInfo";

export const MySummary = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const { data: providerInfo } = useMyProviderInfo();

  if (!providerInfo) return null;

  return (
    <div className="layout-width">
      <div className="col-xl-12">
        <div className="card crm-widget">
          <div className="card-body p-0">
            <div className="row row-cols-md-3 row-cols-1 g-0">
              <div className="col">
                <div className="pt-4 pb-3 px-4">
                  <h5 className="text-muted text-uppercase fs-16 mb-0">
                    Provider
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      {providerInfo ? (
                        <span className="counter-value">
                          <img
                            src={getLogoSrc(
                              providerInfo.provider_id,
                              currentLocation?.state_id
                            )}
                            alt=""
                            height="80"
                          />
                        </span>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="pt-4 pb-3 px-4">
                  <h5 className="text-muted text-uppercase fs-16 mb-3">
                    Total Plans
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      {providerInfo ? (
                        <h2 className="mb-0">
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              separator=","
                              end={providerInfo.plan_nums}
                              duration={1}
                            />
                          </span>
                        </h2>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="pt-4 pb-3 px-4">
                  <h5 className="text-muted text-uppercase fs-16 mb-3">
                    Update Time
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      {providerInfo ? (
                        <h2 className="mb-0">
                          <span className="counter-value">
                            {providerInfo.time}
                          </span>
                        </h2>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
