import React, { useEffect, useState } from "react";
import Loader from "./Common/Loader";
import "../styles/market.css";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { MyChart } from "./MyChart";
import { DataSeriesTypes } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUSNPTCRateTrend,
  fetchUSNPTCRetailHeadroom,
} from "../api/usnMarket";
import moment from "moment";
import { monthToType } from "../api/common";
import { Auth } from "aws-amplify";
import { changeGetAccessModalDisplay } from "../store/actions";
import MyTooltip from "./Common/Tooltip";
import { selectUtility } from "../store/utility/selector";
import DataTable from "react-data-table-component";

const USN_PTCRateTrends = () => {
  const dispatch = useDispatch();
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const [range, setRange] = useState("12");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DataSeriesTypes[]>([]);
  const [dataSource, setDataSource] = useState<any>(null);
  const [priceComposition, setPriceComposition] = useState<any>([]);
  const [isLogin, setIsLogin] = useState(false);
  const currentUtility = useSelector(selectUtility);

  useEffect(() => {
    const checkAuth = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) setIsLogin(true);
    };

    checkAuth();
  }, [Auth]);

  const getAvgPrice = async () => {
    setIsLoading(true);

    const requestData = {
      state_id: currentLocation?.state_id,
      utility_id: currentUtility.utility_id,
      type: monthToType(range),
    };

    const res = await fetchUSNPTCRateTrend(...Object.values(requestData));
    let resData = [];
    if (res.status === 1) {
      resData = res.response;
      const { data_source, peak_source, dalculation_formulas } = res || {};
      setDataSource({
        calculation_formulas: dalculation_formulas || null,
        data_source: data_source || [],
        peak_source: peak_source || [],
      });
    } else {
      setIsLoading(false);
    }

    const ptcData = resData.map((item: any) => {
      if (item.ptc !== 0) {
        return {
          x: moment(item.date, "YYYY-MM").format("MM/DD/YYYY"),
          y: +item.ptc,
        };
      } else {
        return {
          x: moment(item.date, "YYYY-MM").format("MM/DD/YYYY"),
          y: null,
        };
      }
    });

    setData([
      {
        id: "PTC Rate",
        data: ptcData.filter((i: any) => i.y),
      },
    ]);
    setIsLoading(false);
  };

  const getDetail = async () => {
    const requestData = {
      state_id: currentLocation?.state_id,
      utility_id: currentUtility.utility_id,
      type: monthToType(range),
    };

    const res = await fetchUSNPTCRetailHeadroom(...Object.values(requestData));

    if (res?.status === 1) {
      const { response } = res || {};

      setPriceComposition(response);
    } else {
      setPriceComposition([]);
    }
  };

  useEffect(() => {
    if (!currentUtility) return;
    getAvgPrice();
    getDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUtility, range]);

  // const checkAuthAndSetValue = async (val: string) => {
  //   if (val !== "3") {
  //     const auth = await Auth.currentUserInfo();
  //     if (auth) {
  //       setRange(val);
  //     } else {
  //       // if (localStorage.getItem("received_inquiry")) {
  //       //   successnotify(
  //       //     "We have received your inquiry. Please be patient, and we will contact you as soon as possible!"
  //       //   );
  //       // } else {
  //       dispatch(changeGetAccessModalDisplay(true));
  //       // }
  //     }
  //   } else {
  //     setRange(val);
  //   }
  // };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "600px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">PTC Trend</h4>
          {dataSource && isLogin && (
            <MyTooltip>
              {(() => {
                const { calculation_formulas, data_source, peak_source } =
                  dataSource;

                return (
                  <div className="d-flex flex-column gap-4">
                    {calculation_formulas && (
                      <div>
                        <h6 style={{ color: "#fff" }}>
                          Data Calculation Formulas
                        </h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: calculation_formulas,
                          }}
                        />
                      </div>
                    )}
                    {data_source.length > 0 && (
                      <div>
                        <h6 style={{ color: "#fff" }}>Data Source</h6>
                        {data_source.map((link: any, idx: any) => (
                          <p key={idx}>
                            <a href={link} target="_blank" rel="noreferrer">
                              {link}
                            </a>
                          </p>
                        ))}
                      </div>
                    )}
                    {peak_source.length > 0 && (
                      <div>
                        <h6 style={{ color: "#fff" }}>Peak Time Data Source</h6>
                        {peak_source.map((link: any, idx: any) => (
                          <p key={idx}>
                            <a href={link} target="_blank" rel="noreferrer">
                              {link}
                            </a>
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })()}
            </MyTooltip>
          )}
        </CardHeader>
        <CardBody>
          {/* <div className="d-flex justify-content-between flex-wrap gap-2 mb-5">
            <div className="d-flex align-items-center flex-wrap gap-2">
              <span>Range:</span>
              {["3", "6", "12"].map((val: string, idx: number) => (
                <Button
                  onClick={() => checkAuthAndSetValue(val)}
                  color={range === val ? "secondary" : "light"}
                  key={idx}
                >
                  {val} Months
                </Button>
              ))}
            </div>
          </div> */}
          {isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <MyChart
                type="ptcRateTrend"
                title={`${range}-Month Rate Change`}
                data={data[0]?.data.length ? data : []}
                tooltipDetail={priceComposition}
              />
            </React.Fragment>
          )}
          {!isLoading && <TrendTable data={data} />}
        </CardBody>
      </Card>
    </div>
  );
};

const TrendTable = ({ data }: { data: any }) => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const currentUtility = useSelector(selectUtility);

  const formatTableData = (data: any) => {
    return data.map((item: any) => {
      const formatted: any = { id: item.id };
      item.data.forEach((entry: any) => {
        formatted[entry.x] = entry.y.toString();
      });
      return formatted;
    });
  };

  const tableData = formatTableData(data) || [];

  const frozenColumns = [
    {
      name: <span className="font-weight-bold fs-14">State</span>,
      selector: () => currentLocation?.state_name,
    },
    {
      name: <span className="font-weight-bold fs-14">Utility Name</span>,
      selector: () => currentUtility?.utility_name,
    },
  ];

  const scrollableColumns = Object.keys(tableData[0] || {})
    .filter((key) => key !== "id")
    .map((dateKey) => ({
      name: moment(dateKey, "MM/DD/YYYY").format("MMM YYYY"),
      selector: (row: any) => `${row[dateKey]}¢`,
    }));

  if (tableData.length === 0) return null;

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2F2F2",
      },
    },
  };
  return (
    <div className="combined-table">
      <div className="fronzen-table">
        <DataTable
          columns={frozenColumns}
          data={tableData}
          paginationPerPage={10}
          customStyles={{
            cells: { style: { backgroundColor: "#fafafa" } },
            ...customStyles,
          }}
          pagination={false}
        />
      </div>
      <div className="scrollable-table">
        <DataTable
          columns={scrollableColumns}
          data={tableData}
          paginationPerPage={10}
          customStyles={customStyles}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default USN_PTCRateTrends;
