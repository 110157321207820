import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Auth } from "aws-amplify";
import { changeREPDisplay, successSnackbar } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProviderList, updateProvider } from "../api/market";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useHistory } from "react-router-dom";
import useMyProviderInfo from "./Hooks/useMyProviderInfo";

export const ChangeRep = () => {
  const dispatch = useDispatch();
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const isTX = currentLocation?.state_id === "TX";
  const [selectedProvider, setSelectedProvider] = useState<any>();
  const [repList, setRepList] = useState<any>([]);
  const { data: providerInfo } = useMyProviderInfo();
  const history = useHistory();
  const { openChangeREP } = useSelector((state: any) => ({
    openChangeREP: state.Layout.openChangeREP,
  }));

  const logout = async () => {
    try {
      await Auth.signOut();
      history.push("/login");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!providerInfo) return;

    getProviderList();
  }, [providerInfo]);

  const handleClose = () => {
    // setSelectedProvider(null);
    dispatch(changeREPDisplay(false));
  };

  const getProviderList = () => {
    fetchAllProviderList().then((res) => {
      if (res.status === 1) {
        setRepList(res.response);
        const findRep = res?.response?.find((p: any) => {
          if (isTX) {
            return p.provider_id === providerInfo?.provider_id;
          } else {
            return p.usn_provider_id === providerInfo?.provider_id;
          }
        });
        setSelectedProvider(findRep || null);
      } else {
        setRepList([]);
      }
    });
  };

  const onSubmit = () => {
    updateProvider(
      selectedProvider.provider_id,
      selectedProvider.usn_provider_id
    ).then((res) => {
      if (res.status === 1) {
        handleClose();
        logout();
        dispatch(successSnackbar(true));
      }
    });
  };

  const handleSelect = (e: any) => {
    const provider = repList.find((p: any) => p.provider_id === e.target.value);

    if (provider) {
      setSelectedProvider({
        provider_id: provider.provider_id,
        usn_provider_id: provider.usn_provider_id,
      });
    }
  };

  return (
    <Modal isOpen={openChangeREP} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>Change REP</ModalHeader>
      <form>
        <ModalBody>
          <p>Select Provider</p>
          <FormControl fullWidth>
            <Select
              labelId="provider-selecter-label"
              id="provider-selecter"
              label="Provider"
              value={selectedProvider?.provider_id || ""}
              onChange={(e) => handleSelect(e)}
            >
              {repList?.map((item: any) => (
                <MenuItem value={item.provider_id} key={item.provider_id}>
                  {item.provider_name || ""}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => onSubmit()}
            disabled={selectedProvider ? false : true}
          >
            Submit
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
