// Layout Type 
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_LAYOUT_MODE = "CHANGE_LAYOUT_MODE";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_LAYOUT_POSITION = "CHANGE_LAYOUT_POSITION";
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";
export const CHANGE_SIDEBAR_SIZE_TYPE = "CHANGE_SIDEBAR_SIZE_TYPE";
export const CHANGE_SIDEBAR_VIEW = "CHANGE_SIDEBAR_VIEW";
export const CHANGE_SIDEBAR_IMAGE_TYPE = "CHANGE_SIDEBAR_IMAGE_TYPE";
export const RESET_VALUE = "RESET_VALUE";
export const CHANGE_PROFILE_MODAL_DISPLAY = "CHANGE_PROFILE_MODAL_DISPLAY";
export const CHANGE_PASSWORD_MODAL_DISPLAY = "CHANGE_PASSWORD_MODAL_DISPLAY";
export const CHANGE_REP_MODAL_DISPLAY = "CHANGE_REP_MODAL_DISPLAY";
export const OPEN_SUCCESS_SNACKBAR = "OPEN_SUCCESS_SNACKBAR";
export const EFL_URL_DISPLAY = "EFL_URL_DISPLAY";
export const EFL_URL = "EFL_URL";