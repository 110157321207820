import { api } from "../config";
import { getToken } from "./common";

export const fetchUsages = async () => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        // body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Customize/get_usages`
    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchUsagePlans = async (val) => {
    const newToken = await getToken()
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${newToken}`)

    const formdata = new FormData()
    formdata.append('utility_id', val?.utility_id)
    for (let index = 0; index < val?.usages.length; index++) {
        formdata.append(`usages[${index}]`, val?.usages[index])
    }
    formdata.append('created_ts', val?.created_ts)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }
    const url = `${api.API_URL}Customize/customize_plans`
    const response = await fetch(url, requestOptions)
    return response.json()
}