import {
  CHANGE_LOCATION,
  SET_LOCATION,
  FETCH_STATE_REQUEST,
  FETCH_STATE_SUCCESS,
  FETCH_STATE_FAILURE,
} from "./actionType";

export const changeLocation = (location) => ({
  type: CHANGE_LOCATION,
  payload: location,
});

export const setLocation = (location) => ({
  type: SET_LOCATION,
  payload: location,
});

export const fetchStateRequest = () => ({ type: FETCH_STATE_REQUEST });

export const fetchStateSuccess = (stateList) => ({
  type: FETCH_STATE_SUCCESS,
  payload: stateList,
});

export const fetchStateFailure = (error) => ({
  type: FETCH_STATE_FAILURE,
  payload: error,
});
